












import Vue from 'vue';

import DiscordHeroButton from "@/components/auth/DiscordHeroButton.vue";

export default Vue.extend({
    components: {
        DiscordHeroButton,
    },
    methods: {
        next() {
            const path = this.$route.query.path;
            if (path) {
                this.$router.push(path);
            }
        }
    }
});
